<template>
  <b-overlay :show="loading">
    <div>
      <div class="mb-1">
        <b-card-title>
          <h2>Address Information</h2>
        </b-card-title>
      </div>
      <validation-observer ref="update_form" #default="{invalid}">
        <b-form ref="form" @submit.prevent="onUpdate">
          <b-card title="Address">
            <b-row>
              <b-col md="12">
                <b-form-group label="Country" label-for="country">
                  <template #label>
                    <span>Country<span style="color: tomato">*</span></span>
                  </template>
  
                  <validation-provider #default="{ errors }" name="country" rules="required">
                    <v-select
                      id="country"
                      v-model="country"
                      :loading="loading"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countryOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col cols="12">
                <b-form-group label="Region/State/Province" label-for="region">
                  <template #label>
                    <span>Region/State/Province<span style="color: tomato">*</span></span>
                  </template>
  
                  <validation-provider #default="{ errors }" name="region" rules="required">
                    <v-select
                      id="region"
                      v-model="region"
                      :loading="loading"             
                      :clearable="false"
                      :options="regionOptions"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
              <b-col cols="12">
                <b-form-group label="City" label-for="city">
                  <template #label>
                    <span>City<span style="color: tomato">*</span></span>
                  </template>
  
                  <validation-provider #default="{ errors }" name="city" rules="required">
                    <v-select
                      id="city"
                      v-model="city"
                      :loading="loading"             
                      :clearable="false"
                      :options="cityOptions"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
              <b-col cols="12">
                <b-form-group label="Town/Area" label-for="town">
                  <validation-provider #default="{ errors }" name="town" rules="">
                    <v-select
                      id="town"
                      v-model="town"
                      :loading="loading"             
                      :clearable="false"
                      :options="townOptions"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                  style="width: 150px"
                  :disabled="invalid"
                >
                  Update
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { get } from "lodash";
import { required } from '@validations'
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BOverlay, BForm, BFormGroup, BButton, BCardTitle, BCard, BCardHeader, BCardBody, BRow, BCol, BFormTextarea } from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,

    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BOverlay,
    BCardBody,
    BFormGroup,
    BCardHeader,
    BFormTextarea,
    BCardTitle,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      step: 0,

      country: 2300660,
      region: '',
      city: '',
      town: '',

      countryOptions: [],
      regionOptions: [],
      cityOptions: [],
      townOptions: [],

      // validations
      required
    }
  },
  computed: {
    loaded() {
      return this.step === 4
    }
  },
  watch: {
    currentUser: {
      handler(){
        if (!this.loaded){
          this.country = this.getValueFromSource(this.currentUser, 'country', 2300660);
          this.region = this.getValueFromSource(this.currentUser, 'region');
          this.city = this.getValueFromSource(this.currentUser, 'city');
          this.town = this.getValueFromSource(this.currentUser, 'town');
        }
      },
      deep: true,
      immediate: true
    },
    country: {
      handler(changed, old){
        if (changed && changed !== old){
          this.fetchGeoData(changed, this.onRegionsFetch);
        }
      },
      immediate: true
    },
    region: {
      handler(changed, old){
        if (changed && old !== changed){
          this.fetchGeoData(changed, this.onCitiesFetch);
        }
      },
      immediate: true
    },
    city: {
      handler(changed, old){
        if (changed && old !== changed){
          this.fetchGeoData(changed, this.onTownsFetch);
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchCountries();
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;

        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.geo_name_id
        }));

        if (this.loaded){
          this.regionOptions = [];
          this.cityOptions = [];
          this.townOptions = [];

          this.region = '';
          this.city = '';
          this.town = '';
        } else if (!this.loaded){
          this.step += 1;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchGeoData(geo_id, cb) {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchGeoData({ geo_id });
        const { data } = request.data;
        cb(data)
        if (!this.loaded){
          this.step += 1;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdate(){
      try {
        this.loading = true;
        
        const success = await this.$refs.update_form.validate();
        if (!success){
          return;
        }

        const update_req = await this.useJwt().authService.updateAddress({
          country: this.country,
          region: this.region,
          city: this.city,
          town: this.town
        });
        const { data: user } = update_req.data;
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, user);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Address updated successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onRegionsFetch(data) {
      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
      if (this.loaded){
        this.cityOptions = [];
        this.townOptions = [];
        this.region = '';
        this.city = '';
        this.town = '';
      }
    },
    onCitiesFetch(data) {
      this.townOptions = [];

      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));

      if (this.loaded){
        this.city = '';
        this.town = '';
      }
    },
    onTownsFetch(data) {
      this.townOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));

       if (this.loaded){
        this.town = '';
      }
    }
  },
}
</script>
