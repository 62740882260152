<template>
  <b-overlay :show="loading">

    <template>

      <div>
        <b-row>
          <b-col
              cols="12"
              xl="6"
              md="6"
          >
            <UpdateProfileForm />
          </b-col>

          <b-col>
            <!--   Put mfa here -->
            <MFA />

            <Company v-if="currentUser.user_type === 'provider'" />
            <Address />
          </b-col>
        </b-row>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import MFA from "@/pages/auth/profile/mfa.vue";
import Method from "@/pages/auth/profile/method.vue";
import Address from "@/pages/auth/profile/address.vue";
import Company from "@/pages/auth/profile/company.vue";
import UpdateProfileForm from "@/pages/auth/profile/update.vue";
import {
  BOverlay, BAlert, BLink, BRow, BCol, BCardTitle,
  BCard, BCardBody, BButton, BFormGroup,
  BForm, BFormInput, BMedia, BAvatar, BFormCheckbox,
  BFormFile, BFormDatepicker, BBadge,
  BInputGroup, BInputGroupPrepend,
} from "bootstrap-vue"
import Cleave from 'vue-cleave-component'
import PasswordLock from "@/assets/svg/PasswordLock.svg";

import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'

import { get, cloneDeep } from "lodash";

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import {
  MUTATE_USER_DATA
} from "@/store/config/mutation-keys"


export default {
  components: {
    UpdateProfileForm,
    MFA,
    Method,
    Address,
    PasswordLock,
    Cleave,
    Company,
    BOverlay,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    BCardTitle,
    BForm,
    BFormInput,
    BMedia,
    BAvatar,
    BFormFile,
    BFormCheckbox,
    flatPickr,
    BFormDatepicker,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {},
  data(){
    return {
      loading: false,
      avatarPath: "",
      updatedImage: null,
      userData: null,
      deactivationToken: '',
      code: '',
      cleaveDateOptions: {
        date: true,
        delimiter: '/',
        datePattern: ['Y', 'm', 'd'],
        blocks: [4, 2, 2],
        delimiters: ['-', '-', '-']
      },
      required,
      email
    }
  },
  watch: {
    currentUser: {
      handler(){
        this.avatarPath = this.getValueFromSource(this.currentUser, 'avatar.path', '');
      },
      deep: true,
      immediate: true
    }
  },
  created(){
    this.userData = cloneDeep(this.currentUser)
    this.mfa_conf = this.userData.mfa_conf;
  },
  methods: {
    async onUpdateUser(){
      try {
        this.loading = true;

        const formData = new FormData();

        formData.append('date_of_birth', this.getValueFromSource(this.userData, 'date_of_birth', ''));
        formData.append('residential_address', this.getValueFromSource(this.userData, 'residential_address', ''));

        if (this.updatedImage){
          formData.append("avatar", this.updatedImage);
        }

        const response = await this.useJwt().authService.updateProfile(formData);
        const udpated_user = get(response, 'data.data');
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, udpated_user);

        if (this.updatedImage){
          this.updatedImage = null;
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
          'load',
          () => {
            this.avatarPath = reader.result
            this.updatedImage = file;
          },
          false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },

  }
}
</script>

<style lang="scss">
$neutral : #80A0AF;
$main-color: #144F6A;

.exxtra-input {
  position: relative;
  display: block;
  color:  #80A0AF !important;

  .leading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 12px;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  .trailing {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .otp {
    letter-spacing: 2rem;
    text-align: center;
    font-weight: bold;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: 3.5rem !important;
  }


  input {
    border: 1px solid $neutral;
    border-radius: 5px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    min-width: 50px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.75rem;
    color:  #80A0AF !important;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
    }

    &::placeholder {
      color: $neutral;
      opacity: 0.3;
    }
    &:invalid {
      border-color: red;
    }
    &:required {
      border-color: red;
    }
    &:-ms-input-placeholder {
      color: $neutral;
    }

  }
}

</style>
