var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',[_c('div',{staticClass:"mb-1"},[_c('b-card-title',[_c('h2',[_vm._v("Address Information")])])],1),_c('validation-observer',{ref:"update_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdate($event)}}},[_c('b-card',{attrs:{"title":"Address"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Country","label-for":"country"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Country"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"country","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Region/State/Province","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region/State/Province"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"region","loading":_vm.loading,"clearable":false,"options":_vm.regionOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("City"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"city","loading":_vm.loading,"clearable":false,"options":_vm.cityOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Town/Area","label-for":"town"}},[_c('validation-provider',{attrs:{"name":"town","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"town","loading":_vm.loading,"clearable":false,"options":_vm.townOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.town),callback:function ($$v) {_vm.town=$$v},expression:"town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{staticStyle:{"width":"150px"},attrs:{"block":"","type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Update ")])],1)],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }