<template>
<b-overlay :show="loading">
  <section>
    <div>
      <div class="mb-2">
        <h2>Company Information</h2>
      </div>
      <div>
        <b-card>
          <div class="card-body">
            <validation-observer ref="providerForm">
              <b-form ref="form" @submit.prevent="submitUserForm">

                <div class="mb-1">
                  <b-form-group label="Company Name" label-for="company">
                    <validation-provider #default="{ errors }" name="Company Name" vid="company" rules="required">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="UserIcon" size="20" />
                        </span>
                        <input id="company" v-model="provider.company_name" disabled autocomplete="false">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- <div class="mb-1">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="MailIcon" size="20" />
                        </span>
                        <input id="email" v-model="provider.company_email" autocomplete="false" disabled>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div> -->

                <div class="mb-1">
                  <b-form-group label="TIN" label-for="tin">
                    <validation-provider #default="{ errors }" name="TIN" vid="tin" rules="required|length:15">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="ClipboardIcon" size="20" />
                        </span>
                        <input id="tin" v-model="provider.tin" autocomplete="false">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div>
                  <b-button class="py-1" type="submit" variant="primary" block>
                    Update
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>

        </b-card>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import { get, pick } from "lodash";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BCardTitle,
  BMedia,
  BAvatar,
  BButton,
  BFormFile,
  BFormGroup,
  BForm,
  BOverlay,
  BFormSelect,
  BFormDatepicker
} from "bootstrap-vue";

export default {
  name: "Update",
  components: {
    BCard,
    BForm,
    BMedia,
    BAvatar,
    BButton,
    BOverlay,
    BFormFile,
    BCardTitle,
    BFormGroup,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker
  },
  data() {
    return {
      loading: false,
      provider: {
        tin: '',
        company_email: '',
        company_name: ''
      }
    }
  },
  created() {
    this.fetchProvider()
  },
  methods: {
    async onUpdateProvider(payload = {}) {
      try {
        this.loading = true;

        const formData = new FormData();

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(payload)) {
          formData.append(key, value)
        }

        const response = await this.useJwt().providerService.updateProvider(formData);
        const data = get(response, 'data.data.provider');
        this.provider = data

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Provider has been updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchProvider() {
      try {
        this.loading = true;

        const request = await this.useJwt().providerService.fetchProvider();
        const { data } = request.data;

        this.provider = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async submitUserForm() {
      const success = await this.$refs.providerForm.validate();
      if (!success) {
        return;
      }
      const payload = pick(this.provider, ['company_name', 'tin'])
      this.onUpdateProvider(payload)
    }
  }
}
</script>

<style scoped>

</style>
