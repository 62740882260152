<template>
  <section>
    <div>
      <div v-if="mfaMethod === 'app'">
        <div class=" rounded-lg mb-1">
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <AuthenticatorAppIcon />
            </div>
            <div>
              <div>
                <h5 class="m-0 p-0">
                  Google Authenticator App
                </h5>
              </div>
              <div>
                <p class="m-0 p-0">
                  Scan using an authenticator app
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="mfaMethod === 'email'">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <EmailIcon />
            </div>
            <div>
              <div>
                <h5 class="m-0 p-0">
                  Email
                </h5>
              </div>
              <div>
                <p class="m-0 p-0">
                  OTP is sent to you via email
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="mfaMethod === 'sms'">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <TextMessageIcon />
            </div>
            <div>
              <div>
                <h5 class="m-0 p-0">
                  SMS
                </h5>
              </div>
              <div>
                <p class="m-0 p-0">
                  OTP is sent to your phone
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AuthenticatorAppIcon from "@/assets/svg/AuthenticationAppIcon.svg";
import EmailIcon from "@/assets/svg/emailIcon.svg";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";

export default {
  name: "Method",
  components: {
    EmailIcon,
    TextMessageIcon,
    AuthenticatorAppIcon,
  },
  props: {
    mfaMethod: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped>

</style>
