<template>
<b-overlay :show="loading">
  <section>
    <div>
      <div class="mb-2">
        <h2>Personal Information</h2>
      </div>
      <div>
        <b-card>
          <div class="card-body">
            <validation-observer ref="newUserForm">
              <b-form ref="form" @submit.prevent="submitUserForm">

                <div class="mb-2">
                  <b-media class="text-center">
                    <b-avatar ref="previewEl" badge-variant="transparent" :src="avatarPath" :text="avatarText(`${currentUser.first_name} ${currentUser.last_name}`)" :variant="`light-${resolveStatusVariant(getStatusTextForClient(getValueFromSource(currentUser, 'status')))}`" size="10rem">
                      <template #badge>
                        <b-button variant="primary" class="btn-icon rounded-circle" @click="$refs.avatar.$el.childNodes[0].click()">
                          <feather-icon icon="CameraIcon" />
                        </b-button>
                      </template>
                    </b-avatar>
                  </b-media>

                  <div>
                    <b-form-file v-show="false" ref="avatar" accept="image/*" placeholder="" no-drop @input="onImageRenderer" />
                  </div>
                </div>

                <div class="mb-1">
                  <b-form-group label="First Name" label-for="firstname">
                    <validation-provider #default="{ errors }" name="First Name" vid="firstname" rules="required">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="UserIcon" size="20" />
                        </span>
                        <input id="firstname" v-model="currentUser.first_name" autocomplete="false">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-1">
                  <b-form-group label="Last Name" label-for="lastname">
                    <validation-provider #default="{ errors }" name="Last Name" vid="lastname" rules="required">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="UserIcon" size="20" />
                        </span>
                        <input id="lastname" v-model="currentUser.last_name" autocomplete="false">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-1">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="MailIcon" size="20" />
                        </span>
                        <input id="email" v-model="currentUser.email" autocomplete="false" disabled>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-1">
                  <b-form-group label="Phone" label-for="phone">
                    <validation-provider #default="{ errors }" name="Phone" rules="required|length:10" vid="phone">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="PhoneIcon" size="20" />
                        </span>
                        <input id="phone" v-model="currentUser.phone" autocomplete="false">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-1">
                  <b-form-group label="Date Of Birth" label-for="phone">
                    <validation-provider #default="{ errors }" name="date_of_birth" rules="required" vid="date_of_birth">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="CalendarIcon" size="20" />
                        </span>
                        <b-form-datepicker id="date_of_birth" v-model="currentUser.date_of_birth" class="mb-2"></b-form-datepicker>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mb-2">
                  <b-form-group label="Gender" label-for="gender">
                    <validation-provider #default="{ errors }" name="gender" rules="required" vid="gender">
                      <div class="exxtra-input">
                        <b-form-select
                          id="gender"
                          v-model="currentUser.gender"
                          :options="genderOptions"
                          style="height: 50px"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div>
                  <b-button class="py-1" type="submit" variant="primary" block>
                    Update
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>

        </b-card>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import { cloneDeep, get, pick } from "lodash";
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BCardTitle,
  BMedia,
  BAvatar,
  BButton,
  BFormFile,
  BFormGroup,
  BForm,
  BOverlay,
  BFormSelect,
  BFormDatepicker
} from "bootstrap-vue";

export default {
  name: "Update",
  components: {
    BCard,
    BForm,
    BMedia,
    BAvatar,
    BButton,
    BOverlay,
    BFormFile,
    BCardTitle,
    BFormGroup,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker
  },
  data() {
    return {
      loading: false,
      avatarPath: '',
      userData: null,
      genderOptions: [
        {
          value: "female",
          text: "Female"
        },
        {
          value: "male",
          text: "Male"
        }
      ]
    }
  },
  watch: {
    currentUser: {
      handler() {
        this.avatarPath = this.getValueFromSource(this.currentUser, 'avatar.path', '');
        this.userData = cloneDeep(this.currentUser);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.userData = cloneDeep(this.currentUser)
    this.mfa_conf = this.userData.mfa_conf;
    this.currentUser.date_of_birth = new Date(this.currentUser.date_of_birth)
  },
  methods: {
    async onUpdateUser(payload = {}) {
      try {
        this.loading = true;

        const formData = new FormData();

        if (this.updatedImage) {
          formData.append("avatar", this.updatedImage);
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(payload)) {
          formData.append(key, value)
        }

        const response = await this.useJwt().authService.updateProfile(formData);
        const updated_user = get(response, 'data.data');
        updated_user.date_of_birth = new Date(updated_user.date_of_birth)
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, updated_user);


        if (this.updatedImage) {
          this.updatedImage = null;
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Profile has been updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async submitUserForm() {
      const success = await this.$refs.newUserForm.validate();
      if (!success) {
        return;
      }
      const payload = pick(this.userData, ['first_name', 'last_name', 'date_of_birth', 'phone', 'gender'])
      this.onUpdateUser(payload)
    },
    onImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.updatedImage = file;
          this.avatarPath = reader.result
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }
}
</script>

<style scoped>

</style>
